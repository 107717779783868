.addCredits__wrapper {
  min-height: 93vh;
  background: var(--black);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.addCredits__header {
  h1 {
    font-size: 3rem;
  }

  p {
    font-size: 1.2rem;
  }
}

.addCredits__content {
  display: flex;
  justify-content: center;
}

.creditPackage {
  border: 1px solid var(--yellow);
  margin: 20px;
  min-width: 250px;
}

.creditPackageDescription {
  padding: 10px 40px;

  h4 {
    font-size: 4rem;
  }

  p {
    text-transform: uppercase;
    font-size: 0.7rem;
    margin-top: -15px;
    margin-bottom: 20px;
  }
  h5 {
    color: red;
    font-size: 1.2rem;
  }

  // &:hover {
  // 	border: 5px solid var(--yellow);
  // 	cursor: pointer;
  // 	color: var(--black);
  // }
}

.creditsPackagePrice {
  background: var(--yellow);
  padding: 10px 40px;

  h4 {
    font-size: 2rem;
    color: var(--black);
  }

  &:hover {
    background: var(--blue);

    h4 {
      color: var(--yellow);
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 800px) {
  .addCredits__content {
    flex-direction: column;
  }

  .addCredits__wrapper {
    padding: 75px 25px;
  }

  .addCredits__header {
    h1 {
      font-size: 2rem;
    }
    p {
      font-size: 1rem;
    }
  }

  .creditPackage {
    min-width: 90vw;
  }
  .creditPackageDescription {
    h4 {
      font-size: 4rem;
    }
    p {
      margin-top: 0;
    }
  }

  .creditsPackagePrice {
    h4 {
      padding: 5px 40px;
      font-size: 1.5rem;
    }
  }
}
