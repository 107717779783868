footer {
	background: var(--black);
	min-height: 280px;
}

.footer__wrap {
	height: 100%;
	padding: 0 15px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	border-top: 1px solid var(--offBlack);
}

.footer__socket {
	border-top: 1px solid var(--offBlack);
	font-size: 0.6rem;
	font-weight: lighter;
	color: rgb(226, 226, 226);
	padding: 5px 0;
	display: flex;
	align-items: center;
	margin: 0 10%;
}

.footer__links {
	display: grid;
	gap: 5%;
	grid-template-columns: repeat(4, 1fr);
	margin-bottom: 20px;
	margin: 50px 10%;
}

.footer__block {
	display: flex;
	flex-direction: column;
}

.footer__heading {
	color: white;
	margin-bottom: 15px;
	font-weight: 800;
	font-size: 0.9rem;
	font-family: 'Montserrat', sans-serif;
}
.footer__link {
	color: rgb(122, 122, 122);
	margin-bottom: 20px;
	text-decoration: none;
	letter-spacing: none;
	font-family: 'Montserrat', sans-serif;
	font-size: 0.8rem;
	&:hover {
		color: var(--yellow);
	}
}

.footer__social svg {
	font-size: 1.2rem;
	color: rgb(122, 122, 122);
	margin-right: 10px;
}

.footer__facebook {
	&:hover {
		color: #3b5998;
	}
}
.footer__instagram {
	&:hover {
		color: #8e3cba;
	}
}

.footer__linkedin {
	&:hover {
		color: #0072b1;
	}
}

@media only screen and (max-width: 1000px) {
	.footer__links {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}
	.footer__block {
		margin-bottom: 20px;
	}
	.footer__heading {
		margin-bottom: 10px;
	}
	.footer__link {
		margin-bottom: 10px;
	}

	.footer__socket {
		margin-bottom: 10px;
	}
}
