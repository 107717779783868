.calendar__wrapper {
  min-height: calc(100vh - 100px);
  display: grid;
  grid-template-columns: 300px auto;
  background-color: var(--black);
}

.calendarApp {
  height: calc(100vh - 150px);
  background-color: white;
}

.rbc-toolbar button {
  border-radius: 0px !important;
}

.rbc-toolbar {
  background-color: var(--yellow);
  margin-bottom: 0 !important;
  color: var(--black) !important;

  button {
    border: none !important;
    &:hover {
      border: 1px slid var(--black) !important;
      cursor: pointer;
      background-color: var(--black);
      color: var(--yellow);
    }
  }
}

.rbc-active {
  background-color: var(--black) !important;
  color: var(--yellow) !important;
}

.rbc-event {
  background: var(--yellow) !important;
  color: var(--black);
  border-radius: 0;
  border: 1px solid grey !important;
}

.rbc-today {
  background: #fffae2;
}
.rbc-event-content {
  color: white;
  font-size: 12px;
}

.rbc-allday-cell {
  display: none;
}

.rbc-time-content {
  border-top: 0px;
}

.rbc-btn-group {
  margin-right: 25px;
}

@media screen and (max-width: 1000px) {
  .calendar__wrapper {
    display: flex;
    flex-direction: column;
  }

  .calendarApp {
    padding-top: 0px;
    margin-top: 0;
  }

  .rbc-toolbar {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}
