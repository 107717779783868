.loginForm {
	width: 100%;
}

.login__wrapper {
	width: 80%;
	border: 1px solid rgba(161, 161, 161, 0.288);
	box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px 0px;
}

.login__body {
	padding: 80px 40px;

	button {
		margin-bottom: 20px;
	}

	p {
		text-align: center;
	}
}

.login__error {
	font-size: 0.7rem;
	margin: 10px 0;
	color: red;
}

.login__forgotPassword {
	color: white;
	font-size: 0.8rem;

	&:hover {
		color: var(--yellow);
	}
}

.login__register {
	background: var(--yellow);
	color: var(--black);
	min-width: 100%;
	text-align: center;
	padding: 20px;
	text-transform: uppercase;
	margin-top: 20px;

	&:hover {
		cursor: pointer;
		background: var(--blue);
		color: var(--yellow);
	}
}

.login__registerText {
	color: white;
	margin-top: 20px;
	font-size: 0.8rem;
	text-align: center;
}

@media only screen and (max-width: 1000px) {
	.login__wrapper {
		width: 100%;
		border: none;
		box-shadow: none;
	}

	.login__body {
		padding: 0px 20px;
	}

	.colorBlock {
		display: none;
	}
}
